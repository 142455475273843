import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService,
    getHeadersFormUrlEncoded
} from '@sbt-suite/components';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RsaService } from '..';
import { AUTH_EXTERNAL_USER } from '../../constants/autenticacao';
import { IAutenticacaoService, IResponseMfa, IToken } from '../../models/autenticacao.model';
import { UsuarioExternoAutenticacaoService } from './usuario-externo-autenticacao.service';

@Injectable({
    providedIn: 'root'
})
export class AutenticacaoService {
    URL_BASE = environment.URL_LOGIN + environment.PATH_LOGIN;
    MODE = environment.MODE;

    private serviceSelecionado!: string;
    private _dadosUsuarioSessao!: any;
    private _usuarioAutenticado$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private rsa: RsaService,
        private _localStorage: LocalStorageService,
        @Inject(PERMISSOES_SERVICE_TOKEN) private permissoesService: PermissoesService
    ) {
        this.setUsuarioAutenticado(this.checkAcessToken());
    }

    setServiceAutenticacao(opcao: string): void {
        this.serviceSelecionado = opcao;
        this.localStorageService.setItem('serviceAuth', opcao);
    }

    getServiceAutenticacao(): IAutenticacaoService {
        switch (this.serviceSelecionado) {
            case AUTH_EXTERNAL_USER:
                return new UsuarioExternoAutenticacaoService(this.http, this.rsa);
            default:
                throw new Error('Opção inválida de autenticação');
        }
    }

    getServiceSelecionado() {
        this.serviceSelecionado = this.localStorageService.getItem('serviceAuth');
    }

    validarCodigoMfa(codigo: string, recaptcha: string): Promise<IResponseMfa> {
        const params = new HttpParams().set('mfa', codigo).set('recaptcha', recaptcha);
        return lastValueFrom(
            this.http.post<IResponseMfa>(`${this.URL_BASE}/api/v1/mfa`, params.toString(), {
                headers: getHeadersFormUrlEncoded()
            })
        );
    }

    setUsuarioAutenticado(autenticado: boolean) {
        this._usuarioAutenticado$.next(autenticado);
    }

    checkUsuarioAutenticado(): Observable<boolean> {
        return this._usuarioAutenticado$.asObservable();
    }

    /**
     * verifica se o usuario esta autenticado
     * se tiver retorna um "true" e permite entrar nas páginas protegidas
     * @returns returna um Observable
     */
    getUsuarioAutenticado() {
        const token = this._localStorage.getItem('access_token');
        if (token) {
            const decodedToken: IToken = this._decodeToken(token);
            this._dadosUsuarioSessao = decodedToken;
            return this._dadosUsuarioSessao;
        }
        return null;
    }

    /**
     * verifica se existe o "access_token" de sessão
     * se tiver retorna um "true" e permite entrar nas páginas protegidas
     * @returns returna um booleano
     */
    checkAcessToken() {
        return !!this._localStorage.getItem('access_token');
    }

    private _decodeToken(token: string) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedToken = JSON.parse(
                CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8)
            );
            return decodedToken;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
        }
    }

    decodeToken(token: string) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedToken = JSON.parse(
                CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8)
            );
            return decodedToken;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
            return '';
        }
    }

    setTokens(access_token: string, refresh_token: string) {
        this._localStorage.setItem('access_token', access_token);
        this._localStorage.setItem('refreshToken', refresh_token);
    }

    setPermissoes(access_token: string) {
        const decoded = this.decodeToken(access_token);
        if (decoded) {
            const { resource_access } = decoded;
            const { roles } = resource_access[environment.RESOURCE_ACCESS];
            this.permissoesService.registrarPermissoes(roles);
        }
    }

    clearLocalStorage() {
        this._localStorage.clear();
    }
}
