import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AlertMessageService,
    AplicacaoService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';
import { AutenticacaoService } from './core/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    MODE = environment.MODE;
    URL_LOGOUT = environment.URL_LOGOUT;
    autenticado!: boolean;
    openedMenu: boolean = true;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: menuRoles.itemMenu.omcdb.roles,
            itens: [
                {
                    label: 'Campanhas',
                    icone: 'star',
                    link: '/campanhas',
                    roles: menuRoles.itemMenu.omcdb.roles
                },
                {
                    label: 'Histórico',
                    icone: 'history',
                    link: '/historico',
                    roles: menuRoles.itemMenu.omcdb.roles
                }
            ]
        }
    ];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private autenticacaoService: AutenticacaoService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private _activatedRoute: ActivatedRoute,
        private _aplicacaoService: AplicacaoService,
        @Inject(PERMISSOES_SERVICE_TOKEN) private permissoesService: PermissoesService,
        private _alerts: AlertMessageService,
        private __localStorage: LocalStorageService
    ) {
        permissoesService.initPermissoes();
    }

    ngOnInit() {
        this.checkUsuarioAutenticado();
    }

    checkUsuarioAutenticado() {
        this.autenticacaoService.checkUsuarioAutenticado().subscribe((autenticado) => {
            this.autenticado = autenticado;
        });
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.autenticacaoService.clearLocalStorage();
                this.router.navigateByUrl('/login');
                this.autenticacaoService.setUsuarioAutenticado(false);
                this.localStorageService.clear();
            } catch (error) {
                console.error(error);
            }
        }
    }
}
