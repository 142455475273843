import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import * as JsEncryptModule from 'jsencrypt';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IChavePublica, ICredenciaisUsuario, IRsa } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class RsaService {
    URL_BASE = environment.URL_LOGIN + environment.PATH_LOGIN;

    private _nonceToken!: string;
    private _senhaEncriptada!: string;

    constructor(private http: HttpClient) {}

    async gerarNonceTokenESenha(dados: ICredenciaisUsuario): Promise<IChavePublica> {
        const { email, senha } = dados;
        const opt = this._buscarChavePrivada(email);
        const response = await lastValueFrom(opt);
        const { nonceToken, key } = response;
        this._nonceToken = nonceToken;
        this._gerarChaveRSA(senha, key);
        return this.getDadosEncriptados();
    }

    getDadosEncriptados(): IChavePublica {
        return { nonceToken: this._nonceToken, senhaEncriptada: this._senhaEncriptada };
    }

    private _buscarChavePrivada(email: string): Observable<IRsa> {
        const params = new HttpParams().set('email', email);
        return this.http.post<IRsa>(`${this.URL_BASE}/api/v1/login/step`, params.toString(), {
            headers: getHeadersFormUrlEncoded()
        });
    }

    private _gerarChaveRSA(senha: string, chavePrivada: string) {
        const refChave = '-----BEGIN PUBLIC KEY-----' + chavePrivada + '-----END PUBLIC KEY-----';
        const encrypt = new JsEncryptModule.JSEncrypt();
        encrypt.setPublicKey(refChave);
        const encripted = encrypt.encrypt(senha) as string;
        this._senhaEncriptada = encripted;
    }
}
