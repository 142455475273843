import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';
import { rotaPublicaGuard } from './core/guards/rota-publica.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login/login-externo.module').then((m) => m.LoginExternoModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'redefinir-senha/:token',
        loadChildren: () =>
            import('./pages/redefinir-senha/redefinir-senha.module').then(
                (m) => m.RedefinirSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'recuperar-senha',
        loadChildren: () =>
            import('./pages/recuperar-senha/recuperar-senha.module').then(
                (m) => m.RecuperarSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'check-mfa',
        loadChildren: () =>
            import('./pages/check-mfa/check-mfa.module').then((m) => m.CheckMfaModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'campanha/:id',
        loadComponent: () =>
            import('./pages/campanha/campanha.component').then((c) => c.CampanhaComponent),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.omcdb.roles }
    },
    {
        path: 'campanhas',
        loadComponent: () =>
            import('./pages/campanhas/campanhas.component').then((c) => c.CampanhasComponent),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.omcdb.roles }
    },
    {
        path: 'campanha/:campanhaId/comercial/:comercialId',
        loadComponent: () => import('./pages/votar/votar.component').then((c) => c.VotarComponent),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.omcdb.roles }
    },
    {
        path: 'historico',
        loadComponent: () =>
            import('./pages/historico/historico.component').then((c) => c.HistoricoComponent),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.omcdb.roles }
    },
    {
        path: 'historico/campanha/:id',
        loadComponent: () =>
            import('./pages/historico-campanha/historico-campanha.component').then(
                (c) => c.HistoricoCampanhaComponent
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.omcdb.roles }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
